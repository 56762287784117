import React, { useEffect, useState, useRef  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent } from '@plone/volto/actions';
import PropTypes from 'prop-types';
import { Helmet } from '@plone/volto/helpers';
import 'remixicon/fonts/remixicon.css'

const getPeopleContent = (array = []) =>
  array.map((obj, item) => {
    obj[0] = item;
    return obj;
  }, {}
);

const IsotopeView = ({ data }) => {

    const dispatch = useDispatch();
    const people = useSelector((state) =>
        getPeopleContent(state.search.subrequests.person?.items),
    );

    const content = useSelector((state) => state.workflow.transition);

    useEffect(() => {
        dispatch(
          searchContent(
            '/',
            {
              portal_type: ['person'],
              b_size: 1000,
              fullobjects: true,
            },
            'person',
          ),
        );
    }, [dispatch, content]);

    const handleClick = event => {
        const buttons = document.getElementsByClassName("is-checked");
        Array.prototype.forEach.call(buttons, function(button) {
            button.classList.remove('is-checked');
        })
        event.currentTarget.classList.toggle('is-checked');
    };

    // Init isotope
    const isotope = useRef();
    // store the filter keyword in a state  
    const [filterKey, setFilterKey] = useState('*')
    const [isStateTrue, setIsStateTrue] = useState(false)
       
    const handleFilterKeyChange = key => (event) => {setFilterKey(key); handleClick(event);}

    const setingStates = () => {
        setIsStateTrue(true)
    }

    const setHeight = () => {
        var findClass = document.getElementsByClassName('grid-item');
        var tallest = 0; 
        var tallestImg = 0;
        // Loop over matching divs
        for( var i = 0; i < findClass.length; i++)
        {
            var ele = findClass[i];
            var eleHeight = ele.offsetHeight;
            tallest = (eleHeight>tallest ? eleHeight : tallest); /* look up ternary operator if you dont know what this is */
    
            var img = findClass[i].querySelector('.gridContent');
            var imgHeight = img.offsetHeight;
            tallestImg = (imgHeight>tallestImg ? imgHeight : tallestImg);
         }
        for(var i = 0; i < findClass.length; i++)
        {
            findClass[i].style.height = tallest + "px";
            findClass[i].querySelector('.gridContent').style.height = tallestImg + "px";
        }
    
        setingStates()
    }    

    useEffect(() => {
        if(people.length > 1){
            isotope.current = new Isotope('.gridIsotope', {
                itemSelector: '.grid-item',
                sortBy: 'random',
                layoutMode: 'fitRows',
            })
            // cleanup
            return () => isotope.current.destroy()
        }
    }, [isStateTrue])


    useEffect(() => {
        if(people.length > 1){
            filterKey === '*'
            ? isotope.current.arrange({filter: `*`})
            : isotope.current.arrange({filter: `.${filterKey}`})
        }
    }, [filterKey])

    return(
        <>    
            <Helmet>
                <script defer src="https://unpkg.com/isotope-layout@3/dist/isotope.pkgd.min.js"></script>
            </Helmet>
            <div id="filters" className="button-group filter-button-group">
                <button className='filterBtn is-checked' onClick={handleFilterKeyChange('*')}>Alle</button>
                {data.default_tags != undefined &&
                    data.default_tags.map((filter) => (
                        <button className='filterBtn' onClick={handleFilterKeyChange(filter.tagname)}>{ filter.tagname }</button>
                ))}
            </div> 

            <hr />

            <div className='gridIsotope' onLoad={(setHeight)}>
                {people.map((person) => (
                    <div  className={'grid-item ' + String(person.tags_field).split(",").map(tag =>tag).join(" ")} key={person.UID}>
                        <img className='gridImg' src={person.img_field.download} alt={person.img_field.filename} />
                        <div className='gridContent'>
                            <p className='gridContentName'>{ person.name_field }</p>
                            <p>{ person.position_field }</p>
                            <p>{ person.entrance_field }</p>
                            <a href={'mailto:' + person.email_field}><i className="ri-mail-line"></i></a>
                            {person.tags_field != undefined &&
                                String(person.tags_field).split(",").map((tag, i) => (
                                <button className='gridContentTag' key={person.UID + i}>{ tag }</button>
                            ))} 
                        </div>
                        <div className='gridHover'>
                            <a className='gridHoverBtn' href={person.related_field} target='_self'>Weiteres</a>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

IsotopeView.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
};

export default IsotopeView;